import { API_URL } from "../utils/client";

export const updateMissionStatus = async (token, missionId) => {
    try {
        const body = { missionId };
        console.log(token, body);
        const response = await fetch(`${API_URL}/missionStatus`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        });
        console.log("tjisis response",response)
        if (response.ok== true) {
            return true;
        } else {
            console.log('Failed to update mission status');
            return false;
        }
    } catch (error) {
        console.error('Error in updateMissionStatus:', error);
        return false;
    }
}