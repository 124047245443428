import React, { useEffect, useState } from "react";
import "./presale.css";
import Layout from "../components/Layout/Layout";
import NavBar from "../components/NavBar/NavBar";
import { useNavigate } from "react-router-dom";
import { getExchanges } from "../api/getExchanges";
import { updateExchangeStatus } from "../api/updateExchangeStatus";

import { formatNumber } from "../utils/formatNumber";

const Presale = () => {
  const token = sessionStorage.getItem("token");
  const exchangeId = sessionStorage.getItem("exchangeId");
  const [exchanges, setExchanges] = useState([]);
  const [exchangeStatus, setExchangeStatus] = useState("");
  const [totalVoteCount, setTotalVoteCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState("");
  const [notificationVisibile, setNotificationVisibile] = useState("false");
  const [accessCode, setAccessCode] = useState("4080437773");
  const [percent, setPercent] = useState(0);
  const [isAccessing, setIsAccessing] = useState(false);
  const navigate = useNavigate();
  const handleCopyClick = () => {
    navigator.clipboard.writeText(accessCode);
    setAccessCode("copied");
  };

  useEffect(() => {
    const initiateExchangeStatus = async () => {
      const exchangeData = await getExchanges(token);
      const voteCount = exchangeData?.reduce(
        (total, exchange) => total + exchange.voteCount,
        0
      );
      setExchanges(exchangeData);
      setExchangeStatus(exchangeId);
      setTotalVoteCount(voteCount);

      setPercent((exchangeData[0]?.voteCount * 100) / voteCount);

      setIsLoading(false);
    };
    initiateExchangeStatus();
  }, []);

  const handleClickExchange = async (id) => {
    const apiResult = await updateExchangeStatus(token, id);
    if (!apiResult) {
      setNotification(`Vote incomplete`);
      setNotificationVisibile(true);

      setTimeout(() => {
        setNotificationVisibile(false);
        setTimeout(() => setNotification(""), 3000);
      }, 2000);
    } else {
      sessionStorage.setItem("exchangeId", id);
      setExchangeStatus(id);
      setNotification(`Successfully voted`);
      setNotificationVisibile(true);

      setTimeout(() => {
        setNotificationVisibile(false);
        setTimeout(() => setNotification(""), 3000);
      }, 2000);
    }
  };

  const handleClickAccessNow = () => {
    setIsAccessing(true);
  };

  const goBackToApp = () => {
    setIsAccessing(false);
  };


  return isAccessing ? (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <button
        style={{
          width: "100%",
          padding: "10px 0px",
          background: "linear-gradient(45deg, #5DA8FF, #0064D7)",
          cursor: "poPoppins",
          border: "none",
          fontFamily: "Poppins",
          color: "white",
          fontSize: "1rem",
          fontWeight: "600",
        }}
        onClick={goBackToApp}
      >
        Back To App
      </button>
      <iframe
        style={{ height: "100%", width: "100%", border: "none" }}
        src="https://blue.presalepad.io"
        title="description"
        allowFullScreen
      ></iframe>
    </div>
  ) : (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            paddingInline: "1.875rem",
            // height: "100vh",
          }}
          className="presale__main"
        >
          <img
            src="/Images/Vote/voteLogo.png"
            alt=" Vote Icon"
            style={{ marginTop: "20px", width: "240px", height: "240px" }}
          />
          <span
            style={{
              color: "#F4F6F7",
              fontFamily: "Inter",
              fontSize: "50px",
              fontWeight: "800",
              textAlign: "center",
              lineHeight: "50px",
            }}
            className="pesale__main-head"
          >
            Vote for your favourite exchange!
          </span>
          <div
            style={{
              marginTop: "50px",
              marginBottom: "0.8rem",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "1.25rem",
              background: "rgba(254,241,246,0.1)",
              // backdropFilter: "blur(0.5rem)",
              // WebkitBackdropFilter: "blur(0.5rem)",
              padding: "20px",
              gap: "18px",
            }}
            className="presale__body"
          >
            {
              <span
                style={{
                  width: "100%",
                  color: "#F4F6F7",
                  fontFamily: "Inter",
                  fontSize: "1rem",
                  fontWeight: "600",
                  textAlign: "start",
                }}
                className="pesale__body-head"
              >
                Vote for your favourite exchange
              </span>
            }
            <div
              style={{
                display: "flex",
                width: "100%",

                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {exchanges?.map((exchange) => (
                <div
                  key={exchange._id}
                  style={{
                    border: "none",
                    display: "flex",
                    padding: "12px",

                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#101214",

                    borderRadius: "16px",
                    width: "100%",
                  }}
                >
                  <img
                    src={exchange.photoUrl}
                    alt={exchange.title}
                    style={{
                      width: "41px",
                      height: "41px",
                    }}
                    className="pesale__body-img"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      marginLeft: "12px",
                      width: "100%",
                      gap: "8px",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#F4F6F7",
                        textAlign: "start",
                      }}
                      className="pesale__body-title"
                    >
                      {exchange.title}
                    </span>
                    <div
                      style={{
                        display: "flex",

                        width: "100%",
                        alignItems: "center",
                        gap: "0.25rem",
                      }}
                    >
                      <div style={{ position: "relative", width: "90%" }}>
                        <div
                          style={{
                            height: "0.161875rem",
                            backgroundColor: "rgba(9, 72, 19, 0.1)",
                            borderRadius: "6.25rem",
                            position: "absolute",
                            top: "25%",
                            width: "100%",
                            zIndex: "1",
                          }}
                        ></div>
                        <div
                          style={{
                            height: "14px",
                            backgroundColor: "rgba(224,220,250,0.1)",
                            borderRadius: "30px",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              width:
                                (exchange.voteCount * 100) / totalVoteCount ===
                                0
                                  ? "0%"
                                  : `${Math.round(
                                      (exchange?.voteCount * 100) /
                                        totalVoteCount
                                    )}%`,
                              height: "100%",
                              background: "rgba(134,117,255,1)",
                              borderRadius: "6.25rem",
                              transition: "width 0.1s linear",
                              position: "relative",
                              zIndex: "2",
                            }}
                          >
                            <span
                              style={{
                                width: "10%",
                                fontFamily: "Montserrat",
                                fontStyle: "italic",
                                fontSize: "8px",
                                fontWeight: "800",
                                color: "#101214",
                                marginLeft: "35px",
                                position: "relative",
                                top: "-7px",
                              }}
                            >
                              {percent === 0
                                ? "0%"
                                : `${Math.round(
                                    (exchange?.voteCount * 100) / totalVoteCount
                                  )}%`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    style={{
                      borderRadius: "8px",
                      cursor:
                        exchangeStatus !== "undefined" ? "auto" : "pointer",
                      background:
                        exchangeStatus === "undefined"
                          ? "#1657FF"
                          : exchangeStatus === exchange._id
                          ? "#FE498A"
                          : "#28282B",
                      border: "none",
                      fontFamily: "Inter",
                      color: "#FFFFFF",
                      fontSize: "8px",
                      fontWeight: "600",
                      marginLeft: "12px",
                      opacity:
                        exchangeStatus !== "undefined" &&
                        exchangeStatus !== exchange._id
                          ? "0.4"
                          : "1",
                      marginRight: "12px",
                      padding: "6px 16px",
                      lineHeight: "4.02px",
                    }}
                    onClick={
                      exchangeStatus !== "undefined"
                        ? null
                        : () => handleClickExchange(exchange._id)
                    }
                    className="presale__vote-btn"
                  >
                    {exchangeStatus !== "undefined" &&
                    exchangeStatus === exchange._id
                      ? "Voted"
                      : "Vote"}
                  </button>
                </div>
              ))}
            </div>
            <span
              style={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: "500",
                color: "rgba(244, 246, 247, 0.5)",
                textAlign: "right",
                width: "100%",
              }}
              className="pesale__body-p"
            >
              {formatNumber(totalVoteCount)} Votes
            </span>
          </div>
          {notification && (
            <div
              style={{
                position: "absolute",
                bottom: "1rem",
                zIndex: "2",
                width: "100%",
              }}
            >
              <div
                style={{
                  marginInline: "1.875rem",
                  background: "#FEF1F61A",
                  backdropFilter: "blur(12px)",
                  WebkitBackdropFilter: "blur(12px)",
                  borderRadius: "20px",
                  paddingBlock: "1.25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  opacity: notificationVisibile ? 1 : 0,
                  transition: "opacity 0.5s ease-out",
                  pointerEvents: notificationVisibile ? "auto" : "none",
                }}
              >
                <img
                  src="/Images/Home/notification.svg"
                  alt="Notification Icon"
                  style={{ width: "1.5625rem", height: "1.5625rem" }}
                />
                <span
                  style={{
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "white",
                    marginLeft: "0.375rem",
                  }}
                >
                  {notification}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      <div style={{ height: "120px" }}></div>
      <NavBar />
    </Layout>
  );
};

export default Presale;
