export const formatUserCount = (count) => {
    if (count >= 1000000000) {
        const value = count / 1000000000;
        return Math?.round(value * 10) / 10 + 'B';
    } else if (count >= 1000000) {
        const value = count / 1000000;
        return Math?.round(value * 10) / 10 + 'M';
    } else if (count >= 1000) {
        const value = count / 1000;
        return Math?.round(value * 10) / 10 + 'k';
    }
    return count?.toString();
};
