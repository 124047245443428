import { API_URL } from "../utils/client";

export const joinClan = async (clanId, token) => {
    try {
        const response = await fetch(`${API_URL}/clan/${clanId}/join`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if (response.ok) {
            const data = await response.json();
            const clanData = data.clan;
            return clanData;
        } else {
            throw new Error('Failed to fetch user data');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};