import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllClans } from "../api/getAllClans";
import { sendTelegramMessage } from "../api/sendTelegramMessage";
import { getMedalImage } from "../utils/getMedalImage";
import { debounce } from "../utils/debounce";
import Layout from "../components/Layout/Layout";
import NavBar from "../components/NavBar/NavBar";
import astronaut from "../assets/clanLogo.svg";
import userIcon from "../assets/userIcon.png";
import { formatUserCount } from "../utils/formatUserCount";
const JoinClan = () => {
  const token = sessionStorage.getItem("token");
  const chatId = sessionStorage.getItem("chatId");
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [data, setData] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    const getClansInfo = async () => {
      const allClansData = await getAllClans(token);
      setData(allClansData);
    };

    getClansInfo();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    if (dropdownVisible) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropdownVisible, setDropdownVisible]);

  const handleClick = (id) => {
    navigate(`/joinClan/${id}`);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const createClan = async () => {
    const points = parseInt(sessionStorage.getItem("points"), 10);
    if (points < 500000) {
      localStorage.setItem("notification", "Not enough coins");
      navigate(`/`);
    } else {
      const message = "Please send me the command: /createClan";
      await sendTelegramMessage(chatId, message);
      if (
        window.Telegram &&
        window.Telegram.WebApp &&
        window.Telegram.WebApp.close
      ) {
        window.Telegram.WebApp.close();
      } else {
        console.error("Telegram WebApp API is not available.");
      }
    }
  };
  const debouncedCreateClan = debounce(createClan, 300);

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          padding: "0px 30px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            alignItems: "flex-start",
            position: "relative",
          }}
        >
          <img
            src="/Images/joinclan/join clan.webp"
            alt="Coin Icon"
            style={{ width: "96px", height: "96px" }}
          />
        </div>
        <h1
          style={{
            color: "#F4F6F7",
            fontFamily: "Inter",
            fontSize: "35px",
            fontWeight: "800",
          }}
          className="pesale__main-head"
        >
          Join Clan
        </h1>
        <span
          style={{
            marginTop: "15px",
            // background: 'linear-gradient(90deg, #0B6719 0%, #2AD72D 100%)',
            color: "#F4F6F780",
            // WebkitBackgroundClip: 'text',
            // WebkitTextFillColor: 'transparent',
            fontFamily: "Inter",
            fontStyle: "italic",
            fontSize: "16px",
            fontWeight: "500",
          }}
          className="flip__head-txt"
        >
          These clans are recruiting now.
        </span>
        <span
          style={{
            color: "#F4F6F780",
            // WebkitBackgroundClip: 'text',
            // WebkitTextFillColor: 'transparent',
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: "500",
            fontStyle: "italic",
          }}
          className="flip__head-txt"
        >
          Do you wanna join?
        </span>
        <div style={{ width: "100%" }}>
          <button
            style={{
              width: "100%",
              background: "#1657FF",
              paddingBlock: "0.9rem",
              border: "none",
              marginTop: "50px",
              borderRadius: "20px",
              color: "#F4F6F7",
              fontFamily: "Inter",
              fontSize: "18px",
              cursor: "Inter",
              fontWeight: "500",
            }}
            onClick={toggleDropdown}
            className="invitebottombutton"
          >
            Create Clan
          </button>
          <div
            style={{
              marginTop: "30px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "20px",
              background: "#FEF1F61A",
              // backdropFilter: "blur(0.5rem)",
              // WebkitBackdropFilter: "blur(0.5rem)",
              padding: "20px",
              gap: "10px",
            }}
          >
            {data?.map((item) => (
              <div
                key={item._id}
                onClick={() => handleClick(item._id)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                  cursor: "poInter",
                  background: "#101214",
                  borderRadius: "16px",
                  padding: "12px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    // marginTop: "0.8125rem",
                  }}
                >
                  <img
                    src={item.profilePictureUrl}
                    alt="Avatar"
                    style={{
                      width: "40px",
                      height: "40px",
                      position: "relative",
                      borderRadius: "50%",
                      zIndex: 0,
                    }}
                    className="pesale__body-img"
                  />
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      borderRadius: "9.375rem",
                      background: "rgb(22, 87, 255)",

                      paddingBlock: "0.3125rem",
                      paddingInline: "0.46875rem",
                      position: "relative",
                      top: "-6px",
                      left: "-1px",
                      zIndex: 1,
                    }}
                  >
                    <img
                      src={userIcon}
                      alt="User Icon"
                      style={{ width: "8px", height: "8px" }}
                    />
                    <span
                      style={{
                        color: "white",
                        fontFamily: "Inter",
                        fontSize: "7px",
                        fontWeight: "500",
                        marginLeft: "4px",
                        lineHeight: "9px",
                      }}
                    >
                      {formatUserCount(item.userCount)}
                      {item.userCount > 1000 && "+"}
                      {/* 100k */}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "12px",
                    gap: "0.3125rem",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#F4F6F7",
                      lineHeight: "16px",
                    }}
                    className="earn__main-txt-des"
                  >
                    {item.clanName}
                  </span>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {/* <span
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontSize: "12px",
                        color: "#F4F6F7",
                        opacity: "0.5",
                      }}
                    >
                     {formatUserCount(item.points)}
                      {item.points > 1000 && "+"} 
                   
                    </span> */}
                    {item.energyBonus > 0 && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "500",
                            fontSize: "11px",
                            color: "#F4F6F7",
                            opacity: "50%",
                          }}
                          className="earn__main-txt"
                        >
                          +{item.energyBonus}
                        </span>
                      </div>
                    )}
                    {item.tapBonus > 0 && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <img
                          src={"/Images/joinclan/trademil.png"}
                          alt="Multi Tap Icon"
                        />
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "500",
                            fontSize: "11px",
                            lineHeight: "13px",
                            color: "#F4F6F7",
                          }}
                          className="earn__main-txt"
                        >
                          +{item.tapBonus}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                {item.ranking > 3 ? (
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "700",
                      fontSize: "1rem",
                      color: "#F4F6F7",
                    }}
                  >
                    {item.ranking}
                  </span>
                ) : (
                  <img
                    src={getMedalImage(item.ranking)}
                    alt="Gold Medal"
                    style={{
                      width: "1.875rem",
                      height: "1.875rem",
                      marginLeft: "1.875rem",
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {dropdownVisible && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "0px",
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)",
            paddingInline: "17px",
            background: "#00000066",
            zIndex: "50",
          }}
        >
          <div
            ref={dropdownRef}
            style={{
              width: "100%",
              borderRadius: "1.25rem",
              background: "#F4F6F71A",
              backdropFilter: "blur(114px)",
              WebkitBackdropFilter: "blur(114px)",
              display: "flex",
              flexDirection: "column",
              padding: "52px 24px",
              alignItems: "center",
            }}
          >
            <button
              style={{
                position: "absolute",
                top: "1rem",
                right: "1rem",
                float: "right",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={closeDropdown}
            >
              <img
                src="/Images/Boosts/closeicon.svg"
                alt="Close Icon"
                style={{ width: "1.875rem", height: "1.875rem" }}
              />
            </button>
            <img
              src={astronaut}
              alt="Astronaut"
              style={{ width: "100px", height: "100px" }}
            />
            <span
              style={{
                marginTop: "25px",
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: "28px",
                color: "#F4F6F7",
                lineHeight: "24px",
              }}
              className="joinclan__main-h"
            >
              Create Clan
            </span>
            <span
              style={{
                marginTop: "25px",
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "16px",
                color: "#F4F6F7",
                textAlign: "center",
                opacity: "0.5",
                padding: "0px 18.5px",
              }}
              className="earn__main-txt-des"
            >
              Invite friends to your community and <br /> earn Buzzmi Points
              even faster
            </span>
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "15px",
                color: "#F4F6F7",
              }}
            ></span>
            <div
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "30px",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  fontFamily: "Inter",
                  fontWeight: "700",
                  fontSize: "23px",
                  color: "#F4F6F7",
                }}
                className="flip__head"
              >
                500,000
              </span>
              <span
                style={{
                  fontFamily: "Inter",
                  fontWeight: "400",
                  fontSize: "15px",
                  color: "#F4F6F7",
                  opacity: "0.6",
                  fontStyle: "italic",
                }}
                className="earn__main-txt-des"
              >
                / Per Clan
              </span>
            </div>
            <button
              style={{
                width: "100%",
                background: "#1657FF",
                // paddingBlock: "1.125rem",
                border: "none",
                borderRadius: "20px",
                color: "white",
                fontFamily: "Inter",
                fontSize: "18px",
                fontWeight: "500",
                cursor: "poInter",
                height: "60px",
                // marginBottom: "28px",
                lineHeight: "100%",
                // backdropFilter:"blur(8px)",
              }}
              onClick={debouncedCreateClan}
              className="joinclan__main-btn"
            >
              Continue
            </button>
          </div>
        </div>
      )}
      <div style={{ height: "120px" }}></div>
      <NavBar />
    </Layout>
  );
};

export default JoinClan;
