import { API_URL } from "../utils/client";

export const updateBoostLevel = async (token, boostType) => {
    try {
        const body = { boostType };
        const response = await fetch(`${API_URL}/boost`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        });
        if (response.ok) {
            return true;
        } else {
            console.log('Failed to update mission status');
            return false;
        }
    } catch (error) {
        console.error('Error in getMissionStatus:', error);
        return false;
    }
}