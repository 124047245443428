import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Clan.css";
import Layout from "../components/Layout/Layout";
import { getClan } from "../api/getClan";
import { getClanUsersInfo } from "../api/getClanUsersInfo";
import { joinClan } from "../api/joinClan";
import { leaveClan } from "../api/leaveClan";
import { getMedalImage } from "../utils/getMedalImage";
import { handleInvite } from "../utils/handleInvite";
import { formatNumber } from "../utils/formatNumber";
import { GetLevel } from "./service";
import friendsIcon from "../assets/JoinClanLogo.svg";
import Header from "../components/Header/Header";
import buttonIcon from "../assets/buttonIcon.svg";
import back from "../assets/Back.svg";
import "./HomePage.css";
import NavBar from "../components/NavBar/NavBar";

const Clan = () => {
  const { id } = useParams();
  const token = sessionStorage.getItem("token");
  const userClanId = sessionStorage.getItem("clanId");

  const navigate = useNavigate();
  const [clanData, setClanData] = useState({});
  const [memberData, setMemberData] = useState([]);
  const [clanExist, setClanExist] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  console.log("this is member data", memberData);

  useEffect(() => {
    const getClanInfo = async () => {
      if (userClanId) {
        setClanExist(true);
      }
      const clanInfo = await getClan(id, token);
      const clanUsersInfo = await getClanUsersInfo(id, token);
      setClanData(clanInfo);
      setMemberData(clanUsersInfo || []);
      setIsLoading(false);
    };
    getClanInfo();
  }, [id, token, userClanId]);

  const handleJoinClan = async () => {
    await joinClan(id, token);
    localStorage.setItem(
      "notification",
      `You have joined ${clanData.clanName} clan`
    );
    navigate("/");
  };

  const handleLeaveClan = async () => {
    await leaveClan(id, token);
    localStorage.setItem(
      "notification",
      `You have left ${clanData.clanName} clan`
    );
    navigate("/");
  };

  const handleInviteClick = async () => {
    const chatId = sessionStorage.getItem("chatId");
    const userName = sessionStorage.getItem("userName");
    await handleInvite(chatId, userName);
    if (
      window.Telegram &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.close
    ) {
      window.Telegram.WebApp.close();
    } else {
      console.error("Telegram WebApp API is not available.");
    }
  };
  const handleCloseAndRedirect = (data) => {
    // Close the Telegram Web App first
    const tg = window.Telegram.WebApp;
    tg.close();

    // Redirect to the Telegram link
    window.location.href = `https://t.me/${data}`;
  };

  return (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              // paddingLeft: "30px",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              paddingInline: "30px",
              paddingBottom: "70px",
              // marginTop: "20px",
            }}
            className="clan__main"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "100%",
                paddingTop: "8px",
              }}
            >
              <img
                src={clanData?.profilePictureUrl || friendsIcon}
                alt="Coin Icon"
                style={{ width: "86px", height: "86px", borderRadius: "100px" }}
              />
            </div>
            <h1
              className="buzzmi-community-heading"
              style={{
                color: "#F4F6F7",
                fontFamily: "Inter",
                paddingTop: "40px",
                fontWeight: "800",
                textAlign: "center",
              }}
            >
              {clanData?.clanName || "Buzzmi Community"}
            </h1>

            <div
              style={{
                marginTop: "15px",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              <span
                className="get-ready"
                style={{
                  marginTop: "20px",

                  color: "#F1F1F1",
                  fontFamily: "Inter",

                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {clanData?.description || " Get ready for the Buzzmi launch!"}
              </span>
            </div>
            <span
              className="get-ready"
              style={{
                marginTop: "20px",
                fontSize: "14px",
                color: "#F4F6F780",
                fontFamily: "Inter",

                fontWeight: "600",
                textAlign: "center",
              }}
            >
              https://t.me/
              {clanData?.clanUserName || " Get ready for the Buzzmi launch!"}
            </span>

            <span></span>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",

                  background: "#FEF1F61A",
                }}
                className="clan___middle"
              >
                <button
                  style={{
                    width: "100%",
                    background: "#1657FF",

                    border: "none",

                    color: "#F4F6F7",
                    fontFamily: "Inter",

                    fontWeight: "500",
                    cursor: "Inter",
                  }}
                  className="clan__middle__button"
                  onClick={handleInviteClick}
                >
                  Invite a Friend
                </button>
                {clanExist ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "18.75rem",

                      border: "1px solid transparent",
                      width: "100%",
                      // marginTop: "22px",
                    }}
                  >
                    <button
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "5px 8px 5px 16px",
                        width: "100%",

                        border: "1px solid #509DFF4D",

                        cursor: "pointer",
                        background: "#101214",
                      }}
                      className="clan__middle__2ndbutton"
                      onClick={handleLeaveClan}
                    >
                      <p
                        style={{
                          color: "#F4F6F7",
                          fontFamily: "Inter",

                          fontWeight: "500",
                        }}
                        className="middle__text_clan"
                      >
                        Leave Clan
                      </p>
                      <img
                        src="/Images/joinclan/expaind.svg"
                        className="img______clan"
                        alt="/"
                      />
                    </button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "18.75rem",

                      border: "1px solid transparent",
                      width: "100%",
                      // marginTop: "22px",
                    }}
                  >
                    <button
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "5px 8px 5px 16px",
                        width: "100%",

                        border: "1px solid #509DFF4D",

                        cursor: "pointer",
                        background: "#101214",
                      }}
                      className="clan__middle__2ndbutton"
                      onClick={handleJoinClan}
                    >
                      <p
                        style={{
                          color: "#F4F6F7",
                          fontFamily: "Inter",

                          fontWeight: "500",
                        }}
                        className="middle__text_clan"
                      >
                        Join Clan
                      </p>
                      <img
                        src="/Images/joinclan/expaind.svg"
                        className="img______clan"
                        alt="/"
                      />
                      {/* <div className="jo-button-img">
                      <img
                        style={{ height: "21px", width: "30px" }}
                        src={buttonIcon}
                        alt="Icons"
                        className="icon"
                      />
                    </div> */}
                    </button>
                  </div>
                )}
              </div>
              <div
                style={{
                  marginTop: "30px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: "20px",
                  background: "#FEF1F61A",
                  // backdropFilter: "blur(0.5rem)",
                  // WebkitBackdropFilter: "blur(0.5rem)",
                  marginBottom: "63.14px",
                  padding: "20px",
                  gap: "10px",
                }}
              >
                {memberData.length > 0 ? (
                  memberData.map((item) => (
                    <div
                      key={item.userId}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "space-between",
                        cursor: "Inter",
                        background: "#101214",
                        padding: "12px",
                        borderRadius: "16px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                          // marginTop: "0.8125rem",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            borderRadius: "50%",
                            padding: "5px",
                            background: "#000000",
                            zIndex: 0,
                          }}
                        >
                          <img
                            src={
                              item?.profilePic ? item?.profilePic : friendsIcon
                            }
                            alt="Friends"
                            className="image-boost"
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "100px",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            background: "rgb(22, 87, 255)",
                            position: "relative",
                            top: "-1rem",
                            right: "-1rem",
                            zIndex: 1,
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "500",
                              fontSize: "10px",
                              color: "white",
                              verticalAlign: "middle",
                              display: "inline-block",
                              marginBottom: "5px",
                            }}
                          >
                            {GetLevel(item.score)}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "1rem",
                          gap: "0.3125rem",
                          width: "100%",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "600",
                            // fontSize: "14px",
                            color: "#F4F6F7",
                          }}
                          className="boost-multi__text"
                        >
                          {item.name ? item?.name : item?.fullName}
                        </span>
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "500",
                            // fontSize: "12px",
                            color: "#F4F6F7",
                            opacity: "0.6",
                          }}
                          className="boost__score"
                        >
                          {formatNumber(item.score)}
                        </span>
                      </div>
                      {item.rank > 3 ? (
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "700",
                            fontSize: "14px",
                            color: "#FE498A",

                            marginLeft: "5px",
                          }}
                        >
                          {item.rank}
                        </span>
                      ) : (
                        <img
                          src={getMedalImage(item.rank)}
                          alt="Gold Medal"
                          style={{
                            width: "1.875rem",
                            height: "1.875rem",
                            marginLeft: "1.875rem",
                          }}
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <p
                    style={{
                      color: "#F4F6F7",
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "500",
                      marginBottom: "0px",
                      fontFamily: "Inter",
                    }}
                  >
                    No members found.
                  </p>
                )}
              </div>
            </div>
          </div>
          <NavBar />
        </>
      )}
    </Layout>
  );
};

export default Clan;
