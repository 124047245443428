import { API_URL } from "../utils/client";

export const updateProfileUser = async (token, name, email,userId) => {
    try {
      const body = {};
      body.userId = userId;
      body.name = name;
      body.email=email;
      const response = await fetch(`${API_URL}/mission/updateProfileUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        throw new Error("Failed to update user data");
      } else return true;
    } catch (error) {
      console.error("Error:", error);
    }
  };
  