import { API_URL } from "../utils/client";

export const getMissions = async (token) => {
    try {
        const response = await fetch(`${API_URL}/mission`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if (response.ok) {
            const data = await response.json();
            const missions = data.taskGroups;
            return missions;
        } else {
            throw new Error('Failed to fetch missions');
        }
    } catch (error) {
        console.error('Error in getMissions:', error);
    }
}