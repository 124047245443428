import React, { useRef } from 'react';
import Stars from '../Stars/Stars';
import Header from '../Header/Header';
import './Layout.css';

import gradient_1 from '../../assets/Gradient1.png';
import gradient_2 from '../../assets/Gradient2.png';

const Layout = ({ children }) => {
    const appRef = useRef(null);

    return (
        <div className='top-container' ref={appRef}>
            {/* <Stars appRef={appRef} /> */}
            {/* <Header className="header" /> */}
            {/* <img src={gradient_1} alt="Gradient 1" className='gradient_1' /> */}
            {/* <img src={gradient_2} alt="Gradient 2" className='gradient_2' /> */}
            <main>{children}</main>
        </div>
    );
};

export default Layout;
