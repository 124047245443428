import React from "react";

const BanPage = () =>{
    return (
        <div style={{backgroundColor:"gray", width:'100%', height:'100%', color:'white', display:'flex', justifyContent:'center', alignItems:'center' }}>
            <h1>This app is not available on desktop</h1>
        </div>
    )
}

export default BanPage;