import { API_URL } from "../utils/client";

export const getClanUsersInfo = async (clanId, token) => {
    try {
        const response = await fetch(`${API_URL}/clan/${clanId}/users`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if (response.ok) {
            const data = await response.json();
            const clanUsersData = data.users;
            return clanUsersData;
        } else {
            throw new Error('Failed to fetch user data');
        }
    } catch (error) {
        console.error('Error:', error);
    }
}