import { sendTelegramMessage } from "../api/sendTelegramMessage";

export const handleInvite = async (chatId, userName) => {
    const referralLink = `https://t.me/buzmidev_bot?start=r_${chatId}`;
    const message = `Invite your friends and earn rewards for each one who joins!🎁\n\nShare your referral link:: \`${referralLink}\`\n\nLet’s build a stronger fitness community together! `;
    const forwardMessage = `🏆+1.5k Buzzmi tokens as a first-time bonus\n💎+30k Buzzmi Coins if you have Telegram Premium`;
    const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(forwardMessage)}`;
    const appUrl = `https://buzzmiminiapp.blockmoblabs.com/?userId=${chatId}&userName=${encodeURIComponent(userName)}`;
    const inlineKeyboard = [
        [
            { text: "Invite a friend", url: telegramShareUrl }],
        [
            { text: "Back to Buzzmi", web_app: { url: appUrl } }
        ],
    ];
    await sendTelegramMessage(chatId, message, inlineKeyboard);
}