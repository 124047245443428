import React from "react";

const limits = [0,  8800, 9680, 10648, 11712, 12883, 14171, 15588, 17146, 18860, 21689, 24942, 28683, 
    32985, 37932, 43621, 50164, 57688, 66341, 76292, 91550, 109860, 131832, 158198, 189837,
    227804, 273364, 328036, 393643, 472371, 590463, 738078, 922597, 1153246, 1441557, 1801946,
    2252432, 2815540, 3519425, 4399281, 5719065, 7434784, 9665219, 12564784, 16334219, 21234484,
    27604829, 35886277, 46652160, 60647808, 81874540, 110530629, 149216349, 201442071, 271946795, 367128173, 495623033, 669091094, 903272976, 1219418517, 1707185923, 2390060292, 3346084408, 4684518171, 6558325439,
    9181655614, 12854317859, 17996045002, 25194463002, 40000000000]

const GetLevel = (amount) => {
    for(let i = 0; i<limits.length-1 ;i++){
        if(amount>=limits[i] && amount<limits[i+1]) return i + 1;
    }
    return limits.length;
}

export {
    GetLevel
};