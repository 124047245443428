import React, { useEffect } from "react";
import { useState } from "react";
import "./profile.css";
import Layout from "../../components/Layout/Layout";
import NavBar from "../../components/NavBar/NavBar";

const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [telegramUsername, setTelegramUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");


  return (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <div className="profile__main-top">
            <div className="profile__main">
              <img
                src="/Images/profile/profilemain.png"
                alt="/"
                className="profile__main-img"
              />
              <h1 className="profile__main-head">
                Set Up <br /> Your Profile
              </h1>
            </div>
            <div className="profile__bottom">
              <div className="profile__bottom-input">
                <label>Telegram Username</label>
                <input
                  placeholder="@joebiden"
                  type="text"
                  value={telegramUsername}
                  onChange={(e) => setTelegramUsername(e.target.value)}
                />
              </div>

              <div className="profile__bottom-input">
                <label>
                  Name<span style={{ color: "#FF0004" }}>*</span>
                </label>
                <input
                  placeholder="Enter your name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="profile__bottom-input">
                <label>Email Address</label>
                <input
                  placeholder="Enter your Email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <button className="profile__bottom-btn">Set Up</button>
          </div>
          <NavBar />
        </>
      )}
    </Layout>
  );
};

export default Profile;
