import { API_URL } from "../utils/client";

export const updateFlipStatus = async (token, flipBonus) => {
    try {
        const body = { flipBonus };
        const response = await fetch(`${API_URL}/flip`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        });
        if (response.ok) {
            const data = await response.json();
            const flipSuccess = data.flipSuccess;
            return flipSuccess;
        } else {
            throw new Error('Failed to fetch user data');
        }
    } catch (error) {
        console.error('Error in getMissionStatus:', error);
        return false;
    }
}