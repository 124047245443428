import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Earn.css";
import Layout from "../components/Layout/Layout";
import NavBar from "../components/NavBar/NavBar";

import { getMissionStatus } from "../api/getMissionStatus";
import { updateMissionStatus } from "../api/updateMissionStatus";
import { getMissions } from "../api/getMissions";
import { finished } from "../api/directAdmin";

import rocketIcon from "../assets/imageLogo.svg";
import moveIcon from "../assets/arrow-move.svg";
import tickIcon from "../assets/tickIcon.png";
import notificationIcon from "../assets/notificationIcon.png";
import expandIcon from "../assets/Earn/arrow-down.svg";
import collapseIcon from "../assets/Earn/arrow-right.svg";
import { GetLevel } from "./service";
import { formatNumber } from "../utils/formatNumber";

const Earn = () => {
  const points = sessionStorage.getItem("points");

  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [score, setScore] = useState(0);

  const [missions, setMissions] = useState([]);
  const [missionStatus, setMissionStatus] = useState({ missions: [] });
  const [notification, setNotification] = useState("");
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isEssentialTasksCollapsed, setIsEssentialTasksCollapsed] = useState();
  const [isNewTasksCollapsed, setIsNewTasksCollapsed] = useState(false);
  const [isBonusTasksCollapsed, setIsBonusTasksCollapsed] = useState(false);
  const [isAccessing, setIsAccessing] = useState(false);
  const [siteURL, setSiteURL] = useState(
    "https://discord.com/invite/bluemoon/"
  );
  const [isNavbarVisible, setIsNavbarVisible] = useState(true); // State to control navbar visibility

  console.log(missions, "this is mission");

  useEffect(() => {
    const initiateMissionStatus = async () => {
      try {
        // Fetch mission data and mission status
        const missionData = await getMissions(token);
        const initialMissionStatus = await getMissionStatus(token);

        console.log("this is insie the mission status",initialMissionStatus)

        // Filter missions that are live
        const aliveMissions = missionData.map((mission) => {
          // Loop through each task within the mission and update the photoUrl
          mission.tasks = mission.tasks.map((task) => ({
            ...task,
            photoUrl: `${task.photoUrl}`,
          }));

          return mission;
        });

        // Update state with the filtered mission data and mission status
        setMissions(aliveMissions);
        setMissionStatus(initialMissionStatus || { missions: [] });
      } catch (error) {
        console.error("Error fetching mission data:", error);
      } finally {
        // Ensure loading state is set to false after data fetching
        setIsLoading(false);
      }
    };

    // Call the async function to initiate mission data fetching
    initiateMissionStatus();
  }, [token]);

  const toggleEssentialTasksCollapse = (index) => {
    if (isEssentialTasksCollapsed === index) {
      setIsEssentialTasksCollapsed(null); // Collapse the current section
    } else {
      setIsEssentialTasksCollapsed(index); // Expand the clicked section
    }
  };

  const toggleNewTasksCollapse = () => {
    setIsNewTasksCollapsed(!isNewTasksCollapsed);
  };

  const toggleBonusTasksCollapse = () => {
    setIsBonusTasksCollapsed(!isBonusTasksCollapsed);
  };

  const handleClickMission = async (missionId) => {
    try {
      const mission = missions
        ?.map((m) => {
          const filteredTasks = m.tasks.filter(
            (task) => task._id === missionId
          );
          return {
            ...m,
            tasks: filteredTasks,
          };
        })
        .find((m) => m.tasks.length > 0);
      const apiResult = await updateMissionStatus(token, missionId);

      if(apiResult !==false){

        if (mission?.inviteCount > 0) {
          if (apiResult === false) {
            setNotification(`Task not completed`);
            // setNotificationVisibile(true);
  
            setTimeout(() => {
              // setNotificationVisibile(false);
              setTimeout(() => setNotification(""), 3000);
            }, 2000);
          } else {
            setNotification(`Task completed`);
            // setNotificationVisibile(true);
            // finished(mission._id);
  
            setTimeout(() => {
              // setNotificationVisibile(false);
              setTimeout(() => setNotification(""), 3000);
            }, 2000);
          }
        } else {
          console.log("i am comming in this ")
          // let missionStatue = missionStatus;
          // let arr = missionStatue.missions;
          // missionStatue.missions = arr.push(mission._id);
          // setMissionStatus(missionStatue);
          if (mission?.tasks[0]?.link !== "" && apiResult) {
            const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
            const isAndroid = /Android/i.test(navigator.userAgent);
  
            if (isIOS) {
              console.log(
                "this is mission url",
                mission?.tasks[0]?.link,
                mission
              );
              openInSafari(mission?.tasks[0]?.link);
              // navigate("/?from=browser");
            } else if (isAndroid) {
              openInAndroid(mission?.tasks[0]?.link);
            } else {
              window.open(mission?.tasks[0]?.link, "_blank");
            }
          }
          // return false
        }
      }

    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const pnts = sessionStorage.getItem("points");
    setScore(pnts);
  }, []);

  const openInSafari = (url) => {
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink(url);
    } else {
      window.open(url, '_blank', 'noopener,noreferrer');  // Fallback in case Telegram WebApp is unavailable
    }
  };

  const openInAndroid = (url) => {
    window.open(url, "_blank");
  };

  const goBackToApp = () => {
    setIsAccessing(false);
    setIsLoading(false);
  };

  console.log("this is missions",missionStatus)
  return (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
            paddingBlock: "40px",
            position: "relative",
            paddingBottom: "120px",
          }}
        >
          <button className="level" onClick={() => navigate("/levels")}>
            <p className="numberstext">{Number(score)?.toLocaleString()}</p>
            <p className="leveltext">Level {GetLevel(score)}</p>
          </button>
          <h1
            style={{
              paddingTop: "50px",
              paddingBottom: "50px",
              color: "#F4F6F7",
              fontFamily: "Inter",
              fontSize: "28px",
              fontWeight: "800",
              textAlign: "center",
              lineHeight: "40px",
            }}
            // className="earn__main-head"
          >
            Complete Tasks & <br /> Earn Buzzmi
          </h1>
          <div
            style={{
              width: "100%",
              paddingInline: "30px",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
            className="earn__main"
          >
            {missions?.map((item, index) => {
              const totalBonus = item.tasks.reduce(
                (acc, task) => acc + (task.bonus || 0),
                0
              );

              if (item?.groupName === "New Task") {
                // Sort tasks: Incomplete tasks first, completed tasks last
                let sortedTasks;
                if(missionStatus){

                   sortedTasks = item.tasks.sort((a, b) => {
                    const isCompletedA = missionStatus?.missions.includes(a._id);
                    const isCompletedB = missionStatus?.missions.includes(b._id);
                    return isCompletedA - isCompletedB; // Completed (true) comes last
                  });
                }

                return (
                  <div
                    style={{
                      paddingTop: "18px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingBottom: "20px",
                      display: "flex",
                      flexDirection: "column",
                      background: "rgba(254,241,246,0.1)",
                      borderRadius: "1.25rem",
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{
                          color: "#F4F6F7",
                          fontFamily: "Inter",
                          fontWeight: "600",
                          fontSize: "16px",
                        }}
                        className="earn__main-h"
                      >
                        {item?.groupName}
                      </span>
                      <div
                        style={{
                          display: "flex",
                          gap: "12px",
                          alignItems: "center",
                        }}
                      >
                        {/* Total Bonus Display */}
                      </div>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        marginTop: "18px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {sortedTasks.map((mission) => (
                        <button
                          className="button-class"
                          key={mission._id}
                          style={{
                            border: "none",
                            padding: "12px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            cursor: missionStatus?.missions.includes(mission._id)
                              ? "auto"
                              : "pointer",
                            background: "#101214",
                            borderRadius: "16px",
                            width: "100%",
                            opacity: missionStatus?.missions.includes(
                              mission._id
                            )
                              ? "0.7"
                              : "1",
                          }}
                          onClick={
                            missionStatus?.missions.includes(mission._id)
                              ? null
                              : () => handleClickMission(mission._id)
                          }
                        >
                          <img
                            src={mission.photoUrl}
                            alt={mission.title}
                            style={{
                              width: "40px",
                              height: "40px",
                              background: "black",
                              borderRadius: "100px",
                              padding: "0.2rem",
                            }}
                            className="earn__main-profile-img"
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                              marginLeft: "1rem",
                              width: "100%",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Inter",
                                fontWeight: "500",
                                fontSize: "12px",
                                color: "#F4F6F7",
                                paddingBottom: "8px",
                                lineHeight: "120%",
                                textAlign: "left",
                              }}
                            >
                              {mission?.description}
                            </span>

                            <div
                              style={{
                                textAlign: "start",
                                fontFamily: "Inter",
                                fontWeight: "400",
                                fontSize: "10px",
                                lineHeight: "120%",
                                color: "rgba(244,246,247,0.5)",
                              }}
                              className="earn__main-txt"
                            >
                              <div style={{ display: "flex", gap: "4px" }}>
                                Earn
                                <span
                                  style={{
                                    fontFamily: "Inter",
                                    fontWeight: "400",
                                    fontSize: "10px",
                                    color: "rgba(244,246,247,0.5)",
                                  }}
                                >
                                  +{formatNumber(mission.bonus)}
                                </span>
                                Buzzmi
                              </div>
                            </div>
                          </div>
                          {missionStatus?.missions.includes(mission._id) ? (
                            <img
                              src={tickIcon}
                              alt="Tick Icon"
                              style={{
                                width: "1.25rem",
                                height: "1.25rem",
                                marginLeft: "5px",
                              }}
                              className="earn__main-txt-img"
                            />
                          ) : (
                            <img
                              src="Images/Earn/arrow.svg"
                              alt="Move Icon"
                              style={{
                                width: "1.25rem",
                                height: "1.25rem",
                                marginLeft: "5px",
                                marginRight: "12px",
                              }}
                              className="earn__main-txt-img"
                            />
                          )}
                        </button>
                      ))}
                    </div>
                  </div>
                );
              }
            })}

            {missions?.map((item, index) => {
              const totalBonus = item.tasks.reduce(
                (acc, task) => acc + (task.bonus || 0),
                0
              );
              let sortedTasks;
              if(missionStatus){

                const completedTasks = item?.tasks.filter((mission) =>
                  missionStatus?.missions.includes(mission._id)
                );
                const nonCompletedTasks = item?.tasks.filter(
                  (mission) => !missionStatus?.missions.includes(mission._id)
                );
  
                 sortedTasks = [...nonCompletedTasks, ...completedTasks];
              }

              // Separate completed and non-completed tasks

              if (item?.groupName !== "New Task")
                return (
                  <div
                    style={{
                      paddingTop: "18px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingBottom: "20px",
                      display: "flex",
                      flexDirection: "column",
                      background: "rgba(254,241,246,0.1)",
                      borderRadius: "1.25rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "poPoppins",
                      }}
                      onClick={() => {
                        toggleEssentialTasksCollapse(index);
                      }}
                    >
                      <span
                        style={{
                          color: "#F4F6F7",
                          fontFamily: "Inter",
                          fontWeight: "600",
                          fontSize: "16px",
                        }}
                        className="earn__main-h"
                      >
                        {item?.groupName}
                      </span>
                      <div
                        style={{
                          background: "transparent",
                          border: "none",
                          color: "white",
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "12px",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              color: "#FE498A",
                              fontFamily: "Inter",
                              fontSize: "10px",
                              fontWeight: "600",
                            }}
                            className="earn__main-p"
                          >
                            +{totalBonus.toLocaleString()}{" "}
                            <span style={{ fontWeight: "500" }}>Buzzmi</span>
                          </p>
                        </div>
                        {isEssentialTasksCollapsed === index ? (
                          <img
                            src={expandIcon}
                            alt="Expand Icon"
                            style={{
                              width: "1.125rem",
                              height: "1.125rem",
                              transform: "rotate(180deg)",
                              marginLeft: "5px",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={collapseIcon}
                            alt="Collapse Icon"
                            style={{
                              width: "1.125rem",
                              marginLeft: "5px",
                              height: "1.125rem",
                              transform: "rotate(90deg)",
                            }}
                          ></img>
                        )}
                      </div>
                    </div>

                    {isEssentialTasksCollapsed === index && (
                      <div
                        style={{
                          width: "100%",
                          marginTop: "18px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {/* Map over the sorted tasks (incomplete on top, completed at the bottom) */}
                        {sortedTasks?.map((mission) => (
                          <button
                            className="button-class"
                            key={mission._id}
                            style={{
                              border: "none",
                              padding: "12px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              cursor: missionStatus?.missions.includes(
                                mission._id
                              )
                                ? "auto"
                                : "pointer",
                              background: "#101214",
                              borderRadius: "16px",
                              width: "100%",
                              opacity: missionStatus?.missions.includes(
                                mission._id
                              )
                                ? "0.7"
                                : "1",
                            }}
                            onClick={
                              missionStatus?.missions.includes(mission._id)
                                ? null
                                : () => handleClickMission(mission._id)
                            }
                          >
                            <img
                              src={mission.photoUrl}
                              alt={mission.title}
                              style={{
                                width: "40px",
                                height: "40px",
                                background: "black",
                                borderRadius: "100px",
                                padding: "0.2rem",
                              }}
                              className="earn__main-profile-img"
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                marginLeft: "1rem",
                                width: "100%",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "Inter",
                                  fontWeight: "500",
                                  fontSize: "12px",
                                  color: "#F4F6F7",
                                  paddingBottom: "8px",
                                  lineHeight: "100%",
                                  textAlign: "left",
                                }}
                              >
                                {mission.description.length > 28
                                  ? `${mission.description.substring(0, 18)}...`
                                  : mission.description}
                              </span>
                              <div
                                style={{
                                  textAlign: "start",
                                  fontFamily: "Inter",
                                  fontWeight: "400",
                                  fontSize: "10px",
                                  lineHeight: "120%",
                                  color: "rgba(244,246,247,0.5)",
                                }}
                                className="earn__main-txt"
                              >
                                <div style={{ display: "flex", gap: "4px" }}>
                                  Earn
                                  <span
                                    style={{
                                      fontFamily: "Inter",
                                      fontWeight: "400",
                                      fontSize: "10px",
                                      color: "rgba(244,246,247,0.5)",
                                    }}
                                  >
                                    +{formatNumber(mission.bonus)}
                                  </span>
                                  Buzzmi
                                </div>
                              </div>
                            </div>
                            {missionStatus?.missions.includes(mission._id) ? (
                              <img
                                src={tickIcon}
                                alt="Tick Icon"
                                style={{
                                  width: "1.25rem",
                                  marginLeft: "5px",
                                  height: "1.25rem",
                                }}
                                className="earn__main-txt-img"
                              />
                            ) : (
                              <img
                                src="Images/Earn/arrow.svg"
                                alt="Move Icon"
                                style={{
                                  width: "1.25rem",
                                  marginLeft: "5px",
                                  height: "1.25rem",
                                  marginRight: "12px",
                                }}
                                className="earn__main-txt-img"
                              />
                            )}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                );
            })}
          </div>
          {notificationVisible && (
            <div className="notification">{notification}</div>
          )}
        </div>
      )}
      <NavBar siteURL={siteURL} isVisible={isNavbarVisible} />
    </Layout>
  );
};

export default Earn;
