import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./flip.css";
import Layout from "../components/Layout/Layout";
import NavBar from "../components/NavBar/NavBar";

import { getFlipStatus } from "../api/getFlipStatus";
import { updateFlipStatus } from "../api/updateFlipStatus";
import { formatUserCount } from "../utils/formatUserCount";
import notificationIcon from "../assets/notify.svg";
import successIcon from "../assets/successIcon.png";
import chillIcon from "../assets/chillIcon.png";
import workoutIcon from "../assets/energy_modal.png";
// import bird from "../assets/bird.png";
import back from "../assets/Back.svg";
import energyIcon from "../assets/energy1.png";
import { GetLevel } from "./service";
import { formatNumber } from "../utils/formatNumber";
import lowBattery from "../assets/energy1.png";
import { getClan } from "../api/getClan";
import { getProfile } from "../api/getProfile";
import { register } from "../api/register";
import { isEmpty } from "../utils/isEmpty";
const dur = 120;
const maxTime = 30;
const Flip = () => {
  const level = Number(localStorage.getItem("level"));
  const token = sessionStorage.getItem("token");
  const points = sessionStorage.getItem("points");
  const navigate = useNavigate();
  const [score, setScore] = useState(points);
  const [sliderValue, setSliderValue] = useState(100);
  const [mode, setMode] = useState(true);
  const [notification, setNotification] = useState("");
  const [notificationVisibile, setNotificationVisibile] = useState(false);
  const [notificationImage, setNotificationImage] = useState(notificationIcon);
  const [isLoading, setIsLoading] = useState(true);
  const [clanInfo, setClanInfo] = useState({});
  const [clan, setClan] = useState(false);
  const [gameValue, setGameValue] = useState(0);

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
  };

  const levels = () => {
    navigate("/levels");
  };

  useEffect(() => {
    const setFlipStatus = async () => {
      const flipStatus = await getFlipStatus(token);
      setMode(flipStatus);
      setIsLoading(false);
    };
    setFlipStatus();
    if (sessionStorage.getItem("clanInfo") === "{}") {
      setClan(false);
      return;
    }
    setClan(true);
    setClanInfo(JSON.parse(sessionStorage.getItem("clanInfo")));
  }, []);

  useEffect(() => {
    let val = Math.floor(score * 0.3);
    setGameValue(val < 500000 ? val : 500000);
  }, [score]);

  const handleClickDouble = async () => {
    const flipBonus = Math.floor((sliderValue * gameValue) / 100);
    const flipSuccess = await updateFlipStatus(token, flipBonus);
    setMode(false);
    if (flipSuccess) {
      setNotification("You Won!");
      setNotificationVisibile(true);
      setNotificationImage(successIcon);
      setScore(+score + +flipBonus);
      setTimeout(() => {
        setNotificationVisibile(false);
        setTimeout(() => setNotification(""), 3000);
      }, 2000);
    } else {
      setNotification("You Lost!");
      setNotificationVisibile(true);
      setNotificationImage(notificationIcon);
      // setScore(score - flipBonus);
      setTimeout(() => {
        setNotificationVisibile(false);
        setTimeout(() => setNotification(""), 3000);
      }, 2000);
    }
  };

  const handleClickContinue = () => {
    navigate("/");
  };

  const handleClickJoinClan = () => {
    navigate("/joinclan");
  };

  const handleClickClan = (id) => {
    navigate(`/joinClan/${id}`);
  };


  return (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              paddingInline: "0.9375rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "43px",
              marginTop: "30px",
            }}
          >
            {clan ? (
              <button
                
                onClick={() => handleClickClan(clanInfo?.id)}
                className="clan"
              >
                <img
                  src={clanInfo?.profilePictureUrl}
                  alt="Avatar"
                  style={{
                    width: "40px",
                    height: "40px",
                    position: "relative",
                    // borderRadius: "50%",
                  }}
                ></img>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#F4F6F7",
                      textAlign: "start",
                      lineHeight: "16px",
                    }}
                  >
                    {clanInfo?.clanName}
                  </span>
                  <div style={{ display: "flex", gap: "10px" }}>
                    {clanInfo?.energyBonus > 0 && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1px",
                        }}
                      >
                        <img
                          src="/Images/Home/lock.png"
                          alt="Energy Icon"
                          style={{ width: "0.875rem", height: "0.875rem" }}
                        />
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "400",
                            fontSize: "0.5rem",
                            color: "white",
                            // marginLeft: "0.25rem",
                          }}
                        >
                          +{clanInfo?.energyBonus}
                        </span>
                      </div>
                    )}
                    {clanInfo?.tapBonus > 0 && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1px",
                        }}
                      >
                        <img
                          src="/Images/Home/icon.png"
                          alt="Multi Tap Icon"
                          style={{ width: "0.875rem", height: "0.875rem" }}
                        />
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "400",
                            fontSize: "0.5rem",
                            color: "white",
                            // marginLeft: "0.25rem",
                          }}
                        >
                          +{clanInfo?.tapBonus}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <span
                  style={{
                    fontFamily: "Inter",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#1657FF",
                    padding: "9px 7px",
                    marginLeft: "20px",
                    // opacity: "0.6",
                  }}
                >
                  {formatUserCount(clanInfo?.points)}
                  {clanInfo?.points > 1000 && "+"}
                </span>
              </button>
            ) : (
              <button className="topbutton" onClick={handleClickJoinClan}>
                Join Clan
                <img
                  src="/Images/Home/arrow.svg"
                  alt="/"
                  style={{ height: "34px", width: "34px" }}
                />
              </button>
            )}
            {/* <button style={{ width: '50%', borderRadius: '18.75rem', background: 'linear-gradient(45deg, #5DA8FF, #0064D7)', marginBlock: '0.3125rem', paddingBlock: '1rem', cursor: 'pointer', border: 'none', fontFamily: 'Inter', color: 'white', fontSize: '1.125rem', fontWeight: '600' }} onClick={handleClickPreSale} disabled={isCharging}>Presale</button> */}
          </div>

          <button
            style={{
              marginTop: "43px",
            }}
            className="level"
            onClick={levels}
          >
            <h2 className="level__heading">{formatNumber(points)}</h2>
            <p className="level__text">LEVEL {GetLevel(points)}</p>
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              padding: "0px 28px",
              marginTop: "30px",
            }}
            className="text__flip-main"
          >
            <p
              style={{
                fontFamily: "Inter",
                fontSize: "18px",
                fontWeight: "600",
                color: "#F4F6F780",

                // WebkitBackgroundClip: "text",
                // WebkitTextFillColor: "transparent",
                marginBottom: "0px",
              }}
              className="flip__txt"
            >
              1,000
            </p>
            <p
              style={{
                fontFamily: "Inter",
                fontSize: "18px",
                fontWeight: "600",
                color: "#FE498A",

                // WebkitBackgroundClip: "text",
                // WebkitTextFillColor: "transparent",
                marginBottom: "0px",
              }}
              className="flip__txt"
            >
              2,000
            </p>
          </div>
          <div
            style={{
              width: "100%",
              paddingInline: "1rem",
              marginBottom: "50px",
              paddingLeft: "28px",
              paddingRight: "28px",
            }}
            className="flip__main-top"
          >
            {mode ? (
              <>
                <div
                  style={{
                    marginTop: "15px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: "20px",
                    background: "#FEF1F61A",

                    // backdropFilter: "blur(1.5rem)",
                    // WebkitBackdropFilter: "blur(1.5rem)",
                    padding: "20px 28.5px",
                    border: "2px solid #ffffff15",
                  }}
                  className="flip__main"
                >
                  <img
                    src={"/Images/games/buzzmi.png"}
                    alt="Chill Icon"
                    style={{ width: "6.25rem", height: "6.25rem" }}
                  />
                  <h1
                    style={{
                      paddingTop: "15px",
                      fontFamily: "Inter",
                      fontWeight: "700",
                      fontSize: "24px",
                      color: "#F4F6F7",
                      textAlign: "center",
                    }}
                    className="flip__head"
                  >
                    Buzzmi x2
                  </h1>
                  <span
                    style={{
                      marginTop: "25px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      fontSize: "16px",
                      color: "#F4F6F780",

                      textAlign: "center",
                      lineHeight: "24px",
                    }}
                    className="flip__head-txt"
                  >
                    Try your luck to double your Buzzmi Points <br /> and Level Up
                    even Faster!
                  </span>
                  {/* <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "400",
                      fontSize: "16px",
                      color: "#F4F6F780",
                      textAlign: "center",
                      marginBottom: "25px",
                    }}
                    className="flip__head-txt"
                  >
                     */}
                  {/* </span> */}
                  <div style={{ textAlign: "center" }}>
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontStyle: "italic",
                        color: "#F4F6F780",
                      }}
                      className="flip__head-txt"
                    >
                      Doubling Probability is{" "}
                    </span>
                    <span
                      style={{
                        fontStyle: "italic",
                        fontFamily: "Inter",
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "#FE498A",

                        // background:
                        //   "linear-gradient(90deg, #0B6719 0%, #2AD72D 100%)",
                        // WebkitBackgroundClip: "text",
                        // WebkitTextFillColor: "transparent",
                      }}
                      className="flip__head-txt"
                    >
                      95%
                    </span>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      fontFamily: "Inter",
                      fontWeight: "700",
                      fontSize: "24px",
                      color: "#F4F6F7",
                    }}
                    className="flip__head-txt1"
                  >
                    {formatNumber(Math.floor((sliderValue * gameValue) / 100))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "25px",
                      position: "relative",
                      width: "98%",
                    }}
                  >
                    <div style={{ position: "relative", width: "100%" }}>
                      <div
                        style={{
                          height: "0.53125rem",
                          backgroundColor: "#FEF1F61A",
                          // border: '2px solid #E0DCFA',
                          borderRadius: "30px",
                          position: "absolute",
                          top: "25%",
                          width: "100%",
                          zIndex: "1",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "0.8125rem",
                          backgroundColor: "transparent",
                          borderRadius: "0.625rem",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            height: "100%",
                            background: "#8675FF",

                            borderRadius: "0.625rem",
                            transition: "width 0.001s linear",
                            position: "relative",
                            zIndex: 2,
                            width: `${sliderValue}%`,
                          }}
                        ></div>
                        <div
                          style={{
                            position: "absolute",
                            left: `${sliderValue}%`,
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "13px",
                            height: "13px",
                            backgroundColor: "#ffffff",
                            borderRadius: "50%",
                            zIndex: 3,
                          }}
                        ></div>
                      </div>
                    </div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={sliderValue}
                      onChange={handleSliderChange}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "0",
                        width: "100%",
                        height: "0.8125rem",
                        opacity: "0",
                        zIndex: "3",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <button
                    style={{
                      width: "100%",
                      background: "#1657FF",

                      paddingBlock: "0.9rem",
                      border: "none",
                      marginTop: "30px",
                      borderRadius: "20px",

                      color: "white",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      cursor: "pointer",
                      fontWeight: "500",
                    }}
                    onClick={handleClickDouble}
                    className="flip__head-btn"
                  >
                    Double
                  </button>
                </div>

              </>
            ) : (
              <div
                style={{
                  marginTop: "15px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: "20px",
                  background: "#FEF1F61A",
                  // backdropFilter: "blur(1.5rem)",
                  // WebkitBackdropFilter: "blur(1.5rem)",
                  padding: "20px 32px",
                  border: "2px solid #ffffff15",
                }}
                className="flip__main"
              >
                <img
                  src={"/Images/games/againflip.png"}
                  alt="Work Out Icon"
                  style={{ width: "100px", height: "100px" }}
                />
                <h1
                  style={{
                    paddingTop: "15px",
                    fontFamily: "Inter",
                    fontWeight: "700",
                    fontSize: "24px",
                    color: "#F4F6F7",
                    textAlign: "center",
                  }}
                  className="flip__head"
                >
                  Loading...
                </h1>
                <span
                  style={{
                    marginTop: "25px",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#F4F6F780",
                    textAlign: "center",
                    lineHeight: "24px",
                  }}
                  className="flip__head-txt"
                >
                  Come Back Tomorrow or Continue Tapping on the Coin
                </span>
                <button
                  style={{
                    width: "100%",
                    background: "#1657FF",
                    paddingBlock: "0.9rem",
                    border: "none",
                    marginTop: "30px",
                    borderRadius: "20px",
                    color: "white",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    // fontWeight: "700",
                    cursor: "pointer",
                    fontWeight: "500",
                  }}
                  onClick={handleClickContinue}
                  className="flip__head-btn"
                >
                  Continue
                </button>
              </div>
            )}
          </div>
          {notification && (
            <div
              style={{
                position: "absolute",
                bottom: "6rem",
                zIndex: "2",
                width: "100%",
                maxWidth: "355px",
              }}
            >
              <div
                style={{
                  marginInline: "1.875rem",
                  background: "#FEF1F61A",
                  // backdropFilter: "blur(1rem)",
                  // WebkitBackdropFilter: "blur(0.5rem)",
                  borderRadius: "20px",
                  height: "57px",
                  paddingBlock: "1.25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  opacity: notificationVisibile ? 1 : 0,
                  marginBottom: "18.14px",
                  transition: "opacity 0.5s ease-out",
                  marginTop: "63.14px",
                  pointerEvents: notificationVisibile ? "auto" : "none",
                }}
              >
                <img
                  src={notificationIcon}
                  alt="Notification Icon"
                  style={{ width: "1.5625rem", height: "1.5625rem" }}
                />
                <span
                  style={{
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: "18",
                    color: "#F4F6F7",
                    marginLeft: "0.375rem",
                  }}
                >
                  {notification}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      <div style={{ height: "100px" }}></div>
      <NavBar />
    </Layout>
  );
};

export default Flip;
