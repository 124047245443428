import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Main from "./Main"; // Import the new Main component

const App = () => {

  // const navigate = useNavigate();

  useEffect(() => {
    // console.log("This is moon coin");

    window.Telegram.WebApp.isVerticalSwipesEnabled = false;

    window.Telegram.WebApp.expand();

    window.Telegram.WebApp.disableVerticalSwipes();




    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.disableVerticalSwipes) {
      window.Telegram.WebApp.isVerticalSwipesEnabled = false;

      window.Telegram.WebApp.expand();
  
      window.Telegram.WebApp.disableVerticalSwipes();
      window.Telegram.WebApp.disableVerticalSwipes();
    } else {
      console.error('Telegram WebApp API is not available.');
    }
  }, []);
  useEffect(() => {
    if (
      window.Telegram &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.disableVerticalSwipes
    ) {
      window.Telegram.WebApp.disableVerticalSwipes();
      window.Telegram.WebApp.expand();
    } else {
      console.error("Telegram WebApp API is not available.");
    }
  }, []);

  return (
    <Router>
      <Main /> {/* Use the new Main component to handle routes and logic */}
    </Router>
  );
};

export default App;
