import { API_URL } from "../utils/client";

export const updateProfile = async (token, updatedPoints, updatedEnergyCount, isCharging) => {
    try {
        const body = {};
        if (updatedPoints !== null) {
            body.score = updatedPoints;
            sessionStorage.setItem('points', updatedPoints);
        }
        if (updatedEnergyCount !== null) {
            body.energyCount = updatedEnergyCount;
        }
        
        body.isCharging = isCharging;

        const response = await fetch(`${API_URL}/profile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        });
        if (!response.ok) {
            throw new Error('Failed to update user data');
        } else return true;
    } catch (error) {
        console.error('Error:', error);
    }
}