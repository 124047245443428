import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./NavBar.css";

const NavBar = ({ isVisible }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get current route location

  // Navigation functions
  const Home = () => {
    navigate("/");
  };
  const flip = () => {
    navigate("/flip");
  };
  const friends = () => {
    navigate("/invite");
  };
  const earn = () => {
    navigate("/earn");
  };
  const boosts = () => {
    navigate("/boosts");
  };
  const handleClickPreSale = () => {
    navigate("/EditProfile");
  };
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);

  // Check which button should be active based on the current route
  const getActiveClass = (path) => {
    const isActive = location.pathname === path;
    return {
      color: isActive ? "#FFFFFF" : "#FFFFFFCC", // Change text color
      backgroundColor: isActive ? "#1657FF1F" : "transparent", // Change background color
      borderBottom: isActive ? "1px solid #1657FF" : "1px solid transparent", // Change border
      borderRadius: isActive ? "10px" : "0px",
    };
  };
  // If the navbar is not visible, return null to hide it
  if (!isVisible) {
    return null;
  }
  return (
    <div className="button-row">
      <button
        className="button-item"
        onClick={Home}
        style={getActiveClass("/")}
      >
        <img src="/Images/Header/image.png" alt="Home" className="icon-Nav" />
        <span style={{ color: getActiveClass("/").color }}>Home</span>
      </button>
      {/* <button className="button-item" onClick={flip}>
        <img
          src="/Images/Header/1.png"
          alt="Flip x2"
          className="icon-Nav"
        />
        <span style={{ color: getActiveClass("/flip") }}>Flip x2</span>
      </button> */}
      <button
        className="button-item"
        onClick={friends}
        style={getActiveClass("/invite")}
      >
        <img src="/Images/Header/2.png" alt="Friends" className="icon-Nav" />
        <span style={{ color: getActiveClass("/invite").color }}>Friends</span>
      </button>
      <button
        className="button-item"
        onClick={earn}
        style={getActiveClass("/earn")}
      >
        <img src="/Images/Header/3.png" alt="Earn" className="icon-Nav" />
        <span style={{ color: getActiveClass("/earn").color }}>Earn</span>
      </button>
      <button
        className="button-item"
        onClick={boosts}
        style={getActiveClass("/boosts")}
      >
        <img src="/Images/Header/4.png" alt="Boosts" className="icon-Nav" />
        <span style={{ color: getActiveClass("/boosts").color }}>Boosts</span>
      </button>
      <button
        className="button-item"
        onClick={handleClickPreSale}
        style={getActiveClass("/EditProfile")}
      >
        <img src="/Images/Header/5.png" alt="Presale" className="icon-Nav" />
        <span style={{ color: getActiveClass("/EditProfile").color }}>
          Profile
        </span>
      </button>
    </div>
  );
};

export default NavBar;
