import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import NavBar from "../components/NavBar/NavBar";
import friendsIcon from "../assets/frinedsLogo.svg";
import telegramUser from "../assets/Mask group.svg";
import telegramPremium from "../assets/premium.svg";
import coinIcon from "../assets/coin.png";
import { getProfile } from "../api/getProfile";
import { handleInvite } from "../utils/handleInvite";
import { formatNumber } from "../utils/formatNumber";
import "./Invite.css";
const Invite = () => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true); // State to control navbar visibility

  const normalReferralCount = +sessionStorage.getItem("normalReferralCount");
  const premiumReferralCount = +sessionStorage.getItem("premiumReferralCount");
  const referralBonus = formatNumber(
    normalReferralCount * 1500 + premiumReferralCount * 30000
  );
  const [memberData, setMemberData] = useState([]);
  console.log(memberData);
  // useEffect
  useEffect(() => {
    const getFriendsInfo = async () => {
      const token = sessionStorage.getItem("token");
      const data = await getProfile(token);
      console.log(data, "this is data");

      let friends = data.friends;
      let arr = [];
      friends.map((friend) => {
        let score = friend.score;
        let userName = "@" + friend.userName;
        let scoreText = score > 1000 ? `${score / 1000}k+` : `${score}`;
        let friendData = friend?.friend;
        arr.push({ id: userName, scoreText, friendData });
      });
      setMemberData(arr);
    };
    getFriendsInfo();
  }, []);
  const handleInviteClick = async () => {
    const chatId = sessionStorage.getItem("chatId");
    const userName = sessionStorage.getItem("userName");
    await handleInvite(chatId, userName);
    if (
      window.Telegram &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.close
    ) {
      window.Telegram.WebApp.close();
    } else {
      console.error("Telegram WebApp API is not available.");
    }
  };
  useEffect(() => {
    // Add class to body when this page is mounted
    document.body.classList.add("page-overflow-hidden");

    return () => {
      // Remove class when this page is unmounted
      document.body.classList.remove("page-overflow-hidden");
    };
  }, []);

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "100%",

          marginTop: "20px",
        }}
        className="invite-top"
      >
        <img
          src={"/Images/invite/topBuzz.png"}
          alt="Friends Icon"
          style={{ width: "96px", height: "96px" }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
            gap: "20px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <button className="invitetop-btn1">
            <p className="invitetop-btn1-text1">
              {normalReferralCount + premiumReferralCount}
            </p>
            <p className="invitetop-btn1-text2">Friends</p>
          </button>

          <button className="invitetop-btn1">
            <p className="invitetop-btn1-text1">{referralBonus}</p>
            <p className="invitetop-btn1-text2">Buzzmi</p>
          </button>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "50px",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: "20px",

              backgroundColor: "#FEF1F61A",
            }}
            className="card"
          >
            <span
              style={{
                color: "#F4F6F7",
                fontFamily: "Inter",
                fontWeight: "600",
                textAlign: "left",
                lineHeight: "16px",
              }}
              className="invite-middletext"
            >
              Invite Friends to Earn Buzzmi Points
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="innermain"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",

                  background: "#101214",
                  width: "49%",
                }}
                className="innerr__card"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  className="inner"
                >
                  <img
                    src={"/Images/invite/telegram.svg"}
                    alt="Telegram User"
                    className="image-invite"
                  />
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#F4F6F7",
                      textAlign: "left",
                    }}
                    className="innercardtext"
                  >
                    Telegram
                  </span>
                </div>

                <button className="invitemiddlebutton">
                  <p className="invitemiddlebutton-text1">1,500</p>
                  <p className="invitemiddlebutton-text2">Buzzmi</p>
                </button>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",

                  background: "#101214",
                  width: "49%",
                }}
                className="innerr__card"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  className="inner"
                >
                  <img
                    src={"/Images/invite/prem.svg"}
                    alt="Telegram User"
                    className="image-invite"
                  />
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#F4F6F7",
                      textAlign: "left",
                    }}
                    className="innercardtext"
                  >
                    Premium
                  </span>
                </div>

                <button className="invitemiddlebutton">
                  <p className="invitemiddlebutton-text1">30k</p>
                  <p className="invitemiddlebutton-text2">Buzzmi</p>
                </button>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={telegramPremium}
                  alt="Telegram Premium"
                  style={{ width: "41px", height: "40px" }}
                />
                <span
                  style={{
                    marginTop: "0.9375rem",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "#00CF21",
                  }}
                >
                  Premium User
                </span>
                <span
                  style={{
                    marginTop: "1.25rem",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                    fontSize: "20px",
                    color: "#00CF21",
                  }}
                >
                  30,000
                </span>
              </div> */}
            </div>
            {/* <div
              style={{
                marginTop: "1.25rem",
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "16px",
                color: "#00CF21",
              }}
            >
              For you and a friend!
            </div> */}
          </div>
          <button className="invitebottombutton" onClick={handleInviteClick}>
            Invite a Friend
          </button>
          {memberData.length ? (
            <div
              style={{
                marginTop: "1.875rem",
                marginBottom: "0.8rem",
                width: "100%",
                maxHeight: "20rem",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "#FEF1F61A",
                // backdropFilter: "blur(0.5rem)",
                // WebkitBackdropFilter: "blur(0.5rem)",
                padding: "20px",
                borderRadius: "20px",
                gap: "10px",
              }}
            >
              {memberData.map((item) => (
                <div
                  key={item.userId}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    borderRadius: "16px",
                    justifyContent: "space-between",
                    background: "#101214",
                    padding: "12px",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src="/Images/invite/invitebottomprofile.svg"
                      alt="Friends"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "16px",
                        color: "#F4F6F7",
                      }}
                    >
                      {item?.friendData?.name
                        ? item?.friendData?.name
                        : item?.friendData?.fullName}
                    </span>
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "400",
                        fontSize: "11px",
                        color: "#F4F6F7",
                        opacity: "50%",
                        lineHeight: "13.2px",
                      }}
                    >
                      {/* 1000 */}
                      {item.scoreText}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div style={{ height: "120px" }}></div>
      <NavBar isVisible={isNavbarVisible}/>
    </Layout>
  );
};
export default Invite;
