import React, { useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { formatNumber } from "../utils/formatNumber";
import { useNavigate } from "react-router-dom";

import NavBar from "../components/NavBar/NavBar";

const Levels = () => {

  const navigate = useNavigate();
  const limits = [
    8000, 8800, 9680, 10648, 11712, 12883, 14171, 15588, 17146, 18860, 21689,
    24942, 28683, 32985, 37932, 43621, 50164, 57688, 66341, 76292, 91550,
    109860, 131832, 158198, 189837, 227804, 273364, 328036, 393643, 472371,
    590463, 738078, 922597, 1153246, 1441557, 1801946, 2252432, 2815540,
    3519425, 4399281, 5719065, 7434784, 9665219, 12564784, 16334219, 21234484,
    27604829, 35886277, 46652160, 60647808, 81874540, 110530629, 149216349,
    201442071, 271946795, 367128173, 495623033, 669091094, 903272976,
    1219418517, 1707185923, 2390060292, 3346084408, 4684518171, 6558325439,
    9181655614, 12854317859, 17996045002, 25194463002, 40000000000,
  ];

  return (
    <Layout>
      
      <div
        style={{
          width: "100vw",
          // height:'100vh',
          overflow: "auto",
          color: "#F4F6F7",
          padding: "20px",
          fontFamily: "Inter",
        }}
      >
        {limits.map((amount, level) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginBottom: "10px",
            }}
          >
            <span style={{ width: "20%" }}>{level + 1}</span>
            <span style={{ width: "20%" }}>{"====>"}</span>
            <span style={{ width: "40%" }}>{formatNumber(amount)}</span>
          </div>
        ))}
      </div>
      <div style={{ height: "65px" }}></div>
      <NavBar />
    </Layout>
  );
};

export default Levels;
